import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { BookSlotServices } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import moment, { months } from "moment";
import EnvConfig from "../../envConfig";
import "./ManageCourses.css";
import { formatTime } from "../../utilities/functions";
import { useCallback } from "react";

function AppointmentDetails({ pageTitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [id, set_bookingid] = useState(params.slotId);
  const [slotDetails, set_slotDetails] = useState();
  const [pageError, set_pageError] = useState("");
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [isAppointmentAvailable,setIsAppointmentAvailable]=useState(false)


  const { authState } = useSelector((state) => state);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    if (id) {
      fetchAppointmentDetail();
    }
  }, [id]);
  const checkAppointmentAvailability = (appointmentDetails) => {
    const format = 'hh:mm:ss';
    const time = moment(Date.now())
    const startTime = moment(appointmentDetails?.fromDateTime).format('HH:mm:ss')
    const endTime = moment(appointmentDetails?.toDateTime).format('HH:mm:ss')
    const beforeTime = moment(startTime, format);
    const afterTime = moment(endTime, format);
    if (time.isBetween(beforeTime, afterTime) && time.format("YYYY-MM-DD") == moment(appointmentDetails?.fromDateTime).format("YYYY-MM-DD")) {
      setIsAppointmentAvailable(true);
      console.log("true")
    } else {
      setIsAppointmentAvailable(false)
      console.log("false")

    }
  }


  async function fetchAppointmentDetail() {
    try {
      const resp = await trackPromise(BookSlotServices.getSlotAppointmentDetail(id));
      const { data } = resp;
      if (data?.success) {
        set_slotDetails(data?.data);
        checkAppointmentAvailability(data.data)

      } else {
        set_pageError(data.message || "Error in fetching ");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  let timeFormat = "YYYY-MM-DD HH:mm:ss";

  const startTime = moment.utc(slotDetails?.fromDateTime).local().format(timeFormat);
  const endTime = moment.utc(slotDetails?.toDateTime).local().format(timeFormat);
  const currentDateTime = moment(new Date()).format(timeFormat);

  const buttonValidation = useCallback(() => {
    const isAfter = moment(startTime).isAfter(moment(currentDateTime));
    const isBefore = moment(startTime).isBefore(moment(currentDateTime));
    const isBetween = moment(currentDateTime).isBetween(moment(startTime), moment(endTime));
    const isSame = moment(currentDateTime).isSame(moment(startTime));
    // const isMinimumDifference = () => {
    //   if (isAfter) {
    //     return Math.abs(moment(endTime).diff(moment(currentDateTime), "minutes")) > 1;
    //   }
    //   if (isBefore) {
    //     return Math.abs(moment(currentDateTime).diff(moment(startTime), "minutes")) > 1;
    //   }
    // };
    // console.log(Math.abs(moment(startTime).diff(moment(currentDateTime), "minutes")), isBefore, "time diff");
    return isBefore || isAfter || !isBetween || !isSame;
  }, [currentDateTime, endTime, startTime]);

  useEffect(() => {
    const checkTime = () => {
      setButtonEnabled(buttonValidation());
    };

    checkTime();
    const intervalId = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [buttonValidation]);

  const newWindow = () => {
    return window.open(
      `#/${authState?.user?.organisation?.orgSlug}/manageCoaching/appointment/${params.coachId}/${params.slotId}/videoCall`,
      "AghoraVideo.js",
      "_blank"
    );
  };

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Your Coaching Appointment Details</h4>
      </div>
      {slotDetails && (
        <div>
          {/* <div className="coach-card">
            <Container className="appointment-details">
              <Row>
                <div className="topic-name">
                  {`${slotDetails.topic.topicName} | ${slotDetails.coach.firstName} 
                        ${slotDetails.coach.lastName}`}
                </div>
              </Row>
              <Row>
                <div>
                  <FontAwesomeIcon icon={faCalendar} className="appointment-icons" />
                  <span>{moment(slotDetails.fromDateTime).format("dddd, DD, MMM, YYYY")}</span>
                </div>
              </Row>
              <Row>
                <div>
                  <FontAwesomeIcon icon={faClock} className="appointment-icons" />
                  <span>
                    {formatTime(slotDetails?.fromDateTime)} - {formatTime(slotDetails?.toDateTime)}{" "}
                  </span>
                </div>
              </Row>
            </Container>
          </div> */}

          <div>
            <div className="appointment-details">
              <Row>
                <div className="coach-description">
                  <h5>Coach:</h5>
                </div>
              </Row>
              <Row lg="auto">
                <Row>
                  <div className="profile-wrap pe-1">
                    {/* <img className="thumbnail-image" /> */}
                    <Image
                      src={`${EnvConfig.MediaBase}/${slotDetails?.coach?.assetsDir}/${slotDetails?.coach?.profileImage}`}
                      className="coach-Img"
                      alt=""
                    />
                  </div>
                </Row>
                <Col className="skill-detail align-self-center">
                  <div className="title coach-name">{`${slotDetails.coach.firstName} 
                        ${slotDetails.coach.lastName}`}</div>
                </Col>
              </Row>
              <Row className="mt-2">
                <div className="coach-description">
                  <h5>Details:</h5>
                </div>
              </Row>
              <Row className="mb-2">
                <div>
                  <FontAwesomeIcon icon={faCalendar} className="appointment-icons" />
                  <span>{moment(slotDetails?.fromDateTime).format("dddd, DD, MMM, YYYY")}</span>
                </div>
              </Row>
              <Row>
                <div>
                  <FontAwesomeIcon icon={faClock} className="appointment-icons" />
                  <span>
                    {formatTime(slotDetails?.fromDateTime)} - {formatTime(slotDetails?.toDateTime)}{" "}
                  </span>
                </div>
              </Row>

              <Row className="mt-1">
                <div>
                <Button
                  // as={Link}
                  // to="call"
                  onClick={() => newWindow()}
                  // disabled={isButtonEnabled}
                  variant="clr-primary"
                  className="btn-add join-btn mx-0 view-btn px-4 py-2"
                  disabled={!isAppointmentAvailable}

                >
                  Join the Appointment
                </Button>
                </div>
                {!isAppointmentAvailable ? <div className="messagecss error-msg">{`You will be able to join the video call at the scheduled start time of your session. Please refresh or reload the app at that time to access your appointment.`}</div> : <></>}
              </Row>

            </div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default AppointmentDetails;
