import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { BookSlotServices } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import EnvConfig from "../../envConfig";
import { formatTime } from "../../utilities/functions";
import './studentAppointment.css'

function StudentAppointmentDetails({ pageTitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const [id, set_bookingid] = useState(params.slotId);
  const [slotDetails, set_slotDetails] = useState();
  const [pageError, set_pageError] = useState("");
  const [isAppointmentAvailable,setIsAppointmentAvailable]=useState(false)

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    if (id) {
      fetchAppointmentDetail();
    }
  }, [id]);

  const checkAppointmentAvailability = (appointmentDetails) => {
    const format = 'hh:mm:ss';
    const time = moment(Date.now())
    const startTime = moment(appointmentDetails?.fromDateTime).format('HH:mm:ss')
    const endTime = moment(appointmentDetails?.toDateTime).format('HH:mm:ss')
    const beforeTime = moment(startTime, format);
    const afterTime = moment(endTime, format);
    if (time.isBetween(beforeTime, afterTime) && time.format("YYYY-MM-DD") == moment(appointmentDetails?.fromDateTime).format("YYYY-MM-DD")) {
      setIsAppointmentAvailable(true)
      console.log("true")
    } else {
      setIsAppointmentAvailable(false)
      console.log("false")

    }
  }

  async function fetchAppointmentDetail() {
    try {
      const resp = await trackPromise(BookSlotServices.getSlotAppointmentDetail(id));
      const { data } = resp;
      if (data.success) {
        set_slotDetails(data.data);
        checkAppointmentAvailability(data.data)

      } else {
        set_pageError(data.message || "Error in fetching ");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const newWindow = () => {
    return window.open(
      `#/coacheeProgress/appointment/${params.bookingId}/${params.slotId}/videoCall`,
      "AghoraVideo.js",
      "_blank"
    );
  };

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt"> Your Coaching Appointment Details</h4>
      </div>
      {slotDetails && (
        <div>
        

          <div>
            <Container className="appointment-details">
              <Row>
                <div className="coach-description">
                  <h5>Coachee:</h5>
                </div>
              </Row>
              <Row lg="auto">
                <Row>
                  <div className="profile-wrap">
                    {/* <img className="thumbnail-image" /> */}
                    <Image
                      src={`${EnvConfig?.MediaBase}/${slotDetails?.student?.assetsDir}/${slotDetails?.student?.profileImage}`}
                      className="coach-Img img-fluid"
                      alt=""
                    />
                  </div>
                </Row>
                <Col className="skill-detail align-self-center">
                  <div className="title coach-name">{`${slotDetails?.student?.firstName} 
                        ${slotDetails.student.lastName}`}</div>
                  {/* <div className="header-text">{slotDetails.topic.topicName}</div> */}
                </Col>
              </Row>
              <Row className="mt-2">
                <div className="coach-description">
                  <h5>Details:</h5>
                </div>
              </Row>
              <Row className="my-2">
                <div>
                  <FontAwesomeIcon icon={faCalendar} className="appointment-icons" />
                  <span>{moment(slotDetails?.fromDateTime).format("dddd, DD, MMM, YYYY")}</span>
                </div>
              </Row>
              <Row>
                <div>
                  <FontAwesomeIcon icon={faClock} className="appointment-icons" />
                  <span>
                    {formatTime(slotDetails?.fromDateTime)} - {formatTime(slotDetails?.toDateTime)}{" "}
                  </span>
                </div>
              </Row>
              <Row>
                <div className="">
                  <Button
                    // as={Link}
                    // to="call"
                    onClick={() => newWindow()}
                    variant="clr-primary"
                    className="btn-add mx-0 join-btn mx-0 view-btn px-4 py-2"
                    disabled={!isAppointmentAvailable}
                  >
                    Join the Appointment
                  </Button>
                </div>
                {!isAppointmentAvailable ? <div className="messagecss error-msg">{`You will be able to join the video call at the scheduled start time of your session. Please refresh or reload the app at that time to access your appointment.`}</div> : <></>}
              </Row>
            </Container>
          </div>
        </div>
      )}

      <div className="tab-thm-01"></div>
    </Container>
  );
}

export default StudentAppointmentDetails;
